import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

import { THE_BRIDE } from '@/constants';
import { BG_DESKTOP_VIEW } from '@/constants/assets';
import WithAnimation from '@components/Common/WithAnimation';

function LeftContent() {
  return (
    <Box
      bgColor="bgPrimary"
      width="calc(100% - 500px)"
      height="100%"
      pos="fixed"
      bgImage={BG_DESKTOP_VIEW}
      bgSize="cover"
      padding="42px"
      bgPos="center"
      borderRight="8px solid"
      borderRightColor="blackAlpha.400"
    >
      <WithAnimation>
        <Text letterSpacing="4px" fontSize="xs" color="white">
          THE WEDDING OF
        </Text>
      </WithAnimation>
      <WithAnimation>
        <Heading
          margin="32px 0"
          fontWeight="normal"
          size="4xl"
          letterSpacing="2px"
          color="white"
          fontFamily="body"
          textTransform="uppercase"
          dangerouslySetInnerHTML={{ __html: THE_BRIDE.replace('&', '& <br />') }}
        />
      </WithAnimation>
      <WithAnimation>
        <Text fontStyle="italic" maxWidth="500px" color="white">
          {`Marriage is like a music. Both are playing different instruments and parts, but as long as you're playing from the same sheet music, you can create something beautiful.`}
        </Text>
      </WithAnimation>
    </Box>
  );
}

export default LeftContent;
